import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from "../UserDashboard/Sidebar";
import { DelGallery, GetUserUploaded, clearGlry, uploadGallery } from "../../../Reducer/StepForms/gallerySlice";
import Swal from 'sweetalert2';
import { API_BASE_URL } from "../Data/content";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";
import { NavItem } from "react-bootstrap";


export default function UploadGallery() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [files, setFiles] = useState([]);
    const [err, setFormError] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const success = useSelector(state => state.gllry.success);
    const delImage = useSelector(state => state.gllry.delImage);
    const error = useSelector(state => state.gllry.error);
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const data = useSelector(state => state.user.data);

    if (delImage && delImage.status === 200) {

        getData();
        // Swal.fire({
        //     title: 'Congratulation!',
        //     text: delImage.data.message,
        //     icon: 'success'
        // });
        dispatch(clearGlry());

    }

    const Next = () => {
        if (data.selected_plan == null) {
            navigate("/SelfiVerification");
        } else {
            if (data && data.basic === false) {
                navigate("/UserStepForms", { state: "Basic" });
            }
            else if (data && data.astro === false) {
                navigate("/UserStepForms", { state: "Astro" });
            }
            else if (data && data.families === false) {
                navigate("/UserStepForms", { state: "Family" });
            }
            else if (data && data.carrers === false) {
                navigate("/UserStepForms", { state: "Career" });
            }
            else if (data && data.spirituals === false) {
                navigate("/UserStepForms", { state: "Spiritual" });
            }
            else if (data && data.preferences === false) {
                navigate("/UserStepForms", { state: "Preferences" });
            }
            else if (data && data.personalityquestions === false) {
                navigate("/PersonalityQuestions");
            }
            else if (data && data.about === false) {
                navigate("/AboutPersonality");
            }
            else if (data && data.selfy === false) {
                navigate("/SelfiVerification");
            }
            else if (data && data.govtVerification === false) {
                navigate("/IdVerification");
            }
            else if (data && data.privacy === false) {
                navigate("/PrivacySetting");
            } else {
                navigate("/Dashboard");
            }
        }
    }

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearGlry());
        } else {
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            setMsg(success.data.message);
            setBtn(true);
            dispatch(clearGlry());
            // setTimeout(() => {
            //     navigate('/SelfiVerification');
            // }, 2000);
        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearGlry());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearGlry());

        }
    }

    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetUserUploaded(dat));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            if (responseData.photos && responseData.photos.length > 0) {
                setPhotos(responseData.photos);
                // setPhotos(prevPhotos => [
                //     ...prevPhotos,
                //     ...responseData.photos.map(photo => API_BASE_URL + photo)
                // ]);

            }
        }
    }


    const selectFile = async (event) => {
        setFormError("");
        console.log('eveneteeee uploaded', event.target.files);
        const maxSizeInBytes = 4 * 1024 * 1024; // 2MB in bytes

        for (let index = 0; index < event.target.files.length; index++) {
            const element = event.target.files[index];
            if (element.size <= maxSizeInBytes) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(element);
                fileReader.onload = (fe) => {
                    const image = new Image();
                    image.src = fe.currentTarget.result;
                    image.onload = function (ie) {
                        setFiles(prevFiles => [...prevFiles, element]);
                        setPhotos2(prevPhotos => [...prevPhotos, { url: URL.createObjectURL(element), file: element }]);
                        // if (this.naturalHeight === 441 && this.naturalWidth === 600) {
                        //     setFiles(prevFiles => [...prevFiles, element]);
                        //     setPhotos2(prevPhotos => [...prevPhotos, { url: URL.createObjectURL(element), file: element }]);
                        // } else {
                        //     setFormError("Please upload valid image size");
                        // }
                        // console.log('Width: ' + this.naturalHeight);
                        // console.log('Height: ' + this.naturalWidth);
                    }
                }
            } else {
                setFormError("Image size shouldn't be greater than 4 mb");
            }

        }
    }

    const DelImage = (file, url, type) => {
        console.log('file', file, url);
        if (type === "back") {

            let data = { name: file, uid: auth._id };
            dispatch(DelGallery(data));
        } else {
            setPhotos2(photos2.filter(photo => photo.url !== url));

            setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.name !== file.name));

        }
    }


    const uploadPhotos = (e) => {
        setFormError("");
        console.log('files', files);
        if (photos2.length > 0 || photos.length > 0) {
            let formData = new FormData();
            formData.append('uid', auth._id);

            for (let index = 0; index < files.length; index++) {
                formData.append('images', files[index]);

            }
            dispatch(uploadGallery(formData));
        }
        else {
            setFormError("Please select atleast one image");
        }

    }

    return (
        <>
            <section id="full_page_dashboard_withoutSidebar">
                <div class="container-fluid">
                    {/* <Sidebar data={element} sendToParent={childRes} disabled={sidebarDisabled} /> */}

                    <div class="upload_photos">
                        <div class="row">
                            <div class="col-lg-6 pe-xl-5">
                                <div class="box">
                                    <h2><img src="images/Photos-upload.png" alt="" />
                                        <span>Photos</span>
                                        Upload Photos
                                    </h2>
                                    <h3>Photo Upload Guidelines</h3>
                                    <h4>Capture Diversity</h4>
                                    <p>📸 Showcase a mix of single photos, family moments, and travel memories to highlight
                                        various aspects of your life.</p>

                                    <h4>Authenticity Matters</h4>
                                    <p>🌟 Ensure your photos reflect your genuine self without excessive edits or filters.</p>

                                    <h4>Balanced Lifestyle Moments</h4>
                                    <ul>
                                        <li>🌈 Include snapshots from your spiritual or devotee moments, adding depth.</li>
                                        <li>📸 Showcase your social life outside the temple, giving a well-rounded view of your
                                            lifestyle.</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="box-two">

                                    {photos[0] ? (
                                        <>
                                            <div class="imagefile" style={{ backgroundImage: `url(${API_BASE_URL + photos[0]})` }} >
                                                <i class="bi bi-x" onClick={() => DelImage(photos[0], 'url', 'back')}></i></div>
                                        </>
                                    ) : (
                                        <div class="imagefile" style={{ backgroundImage: `url(${"images/img-upload.png"})` }} ></div>
                                    )}
                                    <ul>
                                        {photos.length > 1 && photos.slice(1).map((photo, index) => (
                                            <>
                                                <li key={index} style={{
                                                    backgroundImage: `url(${API_BASE_URL + photo || "images/img-upload.png"})`
                                                }}> <i class="bi bi-x" onClick={() => DelImage(photo, 'url', 'back')}></i>
                                                </li>
                                            </>
                                        ))}
                                        {photos2.length > 0 && photos2.map((ph, index) => (
                                            <>
                                                <li key={index} style={{
                                                    backgroundImage: `url(${ph.url || "images/img-upload.png"})`
                                                }}><i class="bi bi-x" onClick={() => DelImage(ph.file, ph.url, 'front')}></i>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                    <h6><input class="change_placeholder_two" type="file" onChange={selectFile} accept="image/*" multiple /></h6>
                                    <p>Size must be equal to or below 4 MB.</p>
                                    {err && <p className={"error__feedback"}>{err}</p>}
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between mt-5">
                                <Link to="/Dashboard" class="btn">Back to Dashboard</Link>
                                <Link to="/SelfiVerification" class="btn skip">Skip</Link>
                                {btnst === false ?
                                    <a class="btn upload" onClick={uploadPhotos}>Upload</a> :
                                    <a class="btn upload" onClick={() => Next()}>Next</a>
                                    // <Link to="/SelfiVerification" class="btn upload" >Next</Link>
                                }
                            </div>
                            {msg !== "" && <p class="SucessColor">{msg}</p>}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}