import axios from 'axios';
import { API_BASE_URL, WeadingRes } from '../Components/Pages/Data/content';

export const home = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}front/homePage`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const kundaliPageApi = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}front/kundaliPage`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetAboutPage = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}front/GetAboutPage`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const WeadingPlanner = async (formData) => {
  try {
    const response = { data: WeadingRes };
    return response;
  } catch (error) {
    throw error;
  }
};


export const ContactFormSubmit = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}front/ContactFormSubmit`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const CheckKundaliMatch = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}front/CheckKundaliMatch`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};


export const UserRegistration = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/UserRegistration`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const SendOtpUser = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/SendOtpUser`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const Loginnn = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/Loginnn`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GoogleLogin = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GoogleLogin`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const SendForgetPasswordEmail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/SendForgetPasswordEmail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const VerifyForgetPwdOtp = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/VerifyForgetPwdOtp`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const ResetPwdSubmit = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/ResetPwdSubmit`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetUserDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetUserDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const BasicInfoSave = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/BasicInfoSave`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetBasicInfo = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetBasicInfo`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AstroInfoSave = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddAstro`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAstro = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAstro`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetFamily = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetFamily`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const UpdateTermapi = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/updateTerm`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddFamilyInfo = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddFamilyInfo`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetCarrerDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetCarrerDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddCarrerData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddCarrerData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddSpritual = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddSpritual`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetSpritualDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetSpritualDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddPreference = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddPreference`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetPreference = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetPreference`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

// personality step form

export const AddPerosnalityQuetsions = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddPerosnalityQuetsions`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GeterosnalityQuetsions = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GeterosnalityQuetsions`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

// about detailss
export const AddAboutDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddAboutDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetAboutDetail = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAboutDetail`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

// gllryy
export const AddUserGallery = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddUserGallery`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetUploaded = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetUploaded`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const DeleteGallery = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/DeleteGallery`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
//selfi verify

export const AddSelfy = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddSelfy`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetSelfiData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetSelfi`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddGovtIds = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/UploadGovtIds`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetGovtIds = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetGovtIds`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AddPrivacySettings = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddPrivacySettings`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetPrivacySettings = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetPrivacySettings`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const AddMatchmakingChoices = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddMatchmakingChoices`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const AddPricingPlan = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AddPricingPlan`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetUserProfileSide = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetUserProfileSide`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const GetUserTabsData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetUserTabsData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const SaveProfilePhoto = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/SaveProfilePhoto`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetUserMatches = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetUserMatches`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const RecomendationApproval = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/RecomendationApproval`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const MatchRemove = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/MatchRemove`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const ViewUserProfileSide = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/ViewUserProfileSide`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const ViewUserTabsData = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/ViewUserTabsData`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const shortListUser = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/ShortlistUser`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const RemoveFromShortList = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/RemoveFromShortList`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getFilteredData = async (formData, page, pageSize) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/getFilteredData`, formData, page, pageSize);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getSerachResults = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/getSerachResults`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const SendProfileInterest = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/SendProfileInterest`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const AcceptProfileInterest = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/AcceptProfileInterest`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAllNotifications = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAllNotifications`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const ReadAllNOtifications = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/ReadAllNOtifications`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetSinglResourcePage = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}front/GetSinglResourcePage`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAccDetailsSettings = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAccDetailsSettings`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const UpdateAccountDetails = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/UpdateAccountDetails`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const DelUserAccount = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/DelUserAccount`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const UpdateUserPassword = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/UpdateUserPassword`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const SaveUploadedMedia = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/SaveUploadedMedia`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAllChatUsers = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAllChatUsers`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const UpdateBlockUnblock = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/UpdateBlockUnblock`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetAllCoupons = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}user/GetAllCoupons`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}
export const AllPricingPlans = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}user/AllPricingPlans`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const ApplyPromoCheck = async (formData) => {
  console.log('formDa->>>>>>>>>>>>>', formData);
  try {
    const response = await axios.post(`${API_BASE_URL}user/ApplyPromoCheck`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}









