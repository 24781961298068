import { NavLink, useNavigate, Link, useLocation, useParams } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import QRCode from 'react-qr-code';

export default function QR({ setQR }) {
    const qrCodeValue = `9041944445@ybl?amount=${10}`;
    return (
        <>
            <div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Scan Qr to Pay</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setQR(false)}></button>
                        </div>

                    </div>
                    <div class="modal-body">
                        <div class="order_summary m-0 p-0">
                            <div class="row">
                                <QRCode value={qrCodeValue} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )


}