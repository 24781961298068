import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SendOtp, clearStatus } from "../../../../Reducer/authSlice";
const schema = Yup.object().shape({
    email: Yup.string().required("Email is required").email(),
    phone: Yup.string().min(10, "Phone number must be minimum 10 digits").max(12, "Phone must be less than 12 digits").required("Phone number is required"),
    mobile_otp: Yup.string().required("Mobile otp is required"),
    email_otp: Yup.string().required("Email otp is required"),
    password: Yup
        .string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters')
        .max(10, 'Password must be at most 10 characters'),
    confirmPassword: Yup
        .string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    checkboxx: Yup.boolean().oneOf([true], 'You must agree to the Privacy Policy').required('You must agree to the Privacy Policy'),
});

export default function Step1({ onNext }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [emailll, setEmail] = useState('');
    const [phoneee, setPhoneee] = useState('');
    const [otp1, setotp1] = useState();
    const [otp2, setotp2] = useState();
    const [id, setId] = useState("");
    const success = useSelector(state => state.auth.success);
    const error = useSelector(state => state.auth.error);



    useEffect(() => {
        console.log('successs', success);
        if (success && success.status === 200) {
            if (success.data.type && success.data.type) {
                setId(success.data.data);
                Swal.fire({
                    title: 'Congratulation!',
                    text: success.data.message,
                    icon: 'success'
                });
                dispatch(clearStatus());
            }
            // if (success.data.type && success.data.type === "phone") {
            //     // setotp1(success.data.data);
            //     setId(success.data.data);
            //     Swal.fire({
            //         title: 'Congratulation!',
            //         text: success.data.message,
            //         icon: 'success'
            //     });
            //     dispatch(clearStatus());
            // } else if (success.data.type && success.data.type === "email") {
            //     setotp2(success.data.data);
            //     Swal.fire({
            //         title: 'Congratulation!',
            //         text: success.data.message,
            //         icon: 'success'
            //     });
            //     dispatch(clearStatus());
            // }
        }
    }, [success]);

    function sendOtp(type) {

        if (type == 'phone') {
            if (!phoneee) {
                alert("Please fill in the mobile number");
            } else if (!/^\d{10,12}$/.test(phoneee)) {
                alert("Please enter a valid mobile number");
            } else {
                let data = { type: type, data: phoneee, id: id };
                dispatch(SendOtp(data));
            }
        }
        if (type == 'email') {
            if (!emailll) {
                alert("Please fill in the email address");
            } else if (!/\S+@\S+\.\S+/.test(emailll)) {
                alert("Please enter a valid email address");
            } else {
                let data = { type: type, data: emailll, id: id };
                dispatch(SendOtp(data));
            }
        }
    }

    const handleEmailChange = (e) => {
        if (e.target.name == "email") {
            setEmail(e.target.value);
            setotp2(''); // Clear email OTP value when email is changed
        } else {
            setPhoneee(e.target.value);
            setotp1('');
        }

    };

    const onError = (errors) => {
        console.log(errors);
    };

    const onSubmit = async (datas) => {
        // onNext("ste1Data");
        const { mobile_otp, email_otp } = datas;
        let ste1Data = { email: datas.email, mobile: datas.phone, mobile_otp: datas.mobile_otp, email_otp: datas.email_otp, password: datas.password, id: id }
        onNext(ste1Data);
    }

    // if (mobile_otp == otp1 && email_otp == otp2) {

    //     let ste1Data = { email: datas.email, mobile: datas.phone, mobile_otp: datas.mobile_otp, email_otp: datas.email_otp, password: datas.password }
    //     onNext(ste1Data);
    // }
    // else {
    //     alert('Otp mismatch');
    // }
    // };

    return (

        <div class="box-two h-100">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div class="text-center">
                    <a href="index.html">  <img class="logo_login" src="images/Logo.png" alt="" /></a>
                </div>
                <h2>Sign Up </h2>
                <p>Creating a New Account</p>


                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group mess">
                            <label class="form-label">Phone Number</label>
                            <input type="tel" class="form-control" {...register('phone')} onChange={handleEmailChange} />
                            <i class="bi bi-telephone"></i>
                            <span onClick={() => sendOtp('phone')}>Send OTP</span>
                            {errors.phone && <p className={"error__feedback"}>{errors.phone.message}</p>}

                            {/* {otp1 && <b>
                                your mobile otp is : {otp1}
                            </b>} */}

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mess">
                            <label class="form-label">Email ID</label>
                            <input type="email" class="form-control" {...register('email')} onChange={handleEmailChange} />
                            <i class="bi bi-envelope"></i>
                            <span onClick={() => sendOtp('email')}>Send OTP</span>
                            {errors.email && <p className={"error__feedback"}>{errors.email.message}</p>}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Enter OTP sent on Mobile</label>
                            <input type="text" class="form-control" name="mobile_otp"  {...register('mobile_otp')} />
                            {errors.mobile_otp && <p className={"error__feedback"}>{errors.mobile_otp.message}</p>}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Enter OTP sent on Email</label>
                            <input type="text" class="form-control"  {...register('email_otp')} />
                            {errors.email_otp && <p className={"error__feedback"}>{errors.email_otp.message}</p>}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Create Password</label>
                            <input type={showPassword ? "text" : "password"} class="form-control" name="password" {...register('password')} />

                            <i
                                className={`bi bi-eye${showPassword ? "" : "-slash"}`}
                                onClick={() => setShowPassword(!showPassword)}
                            ></i>
                            {errors.password && <p className={"error__feedback"}>{errors.password.message}</p>}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Confirm Password</label>
                            <input type={showPassword2 ? "text" : "password"} class="form-control" name="confirmPassword" {...register('confirmPassword')} />
                            <i
                                className={`bi bi-eye${showPassword2 ? "" : "-slash"}`}
                                onClick={() => setShowPassword2(!showPassword2)}
                            ></i>
                            {errors.confirmPassword && <p className={"error__feedback"}>{errors.confirmPassword.message}</p>}
                        </div>
                    </div>

                    <div class="col-12">
                        <h3>Please read and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                                conditions</a>
                        </h3>
                        <div class="form-check">
                            <input class="form-control form-check-input" type="checkbox" value="true" id="flexCheckDefault" {...register('checkboxx')} />
                            <label class="form-check-label" for="flexCheckDefault">
                            I confirm that I have read and accepted the terms and conditions
                            </label>
                            {errors.checkboxx && <p className={"error__feedback"}>{errors.checkboxx.message}</p>}
                        </div>
                        {/* <h3>Please read <a data-bs-toggle="modal" data-bs-target="#exampleModal">Privacy
                            Policy</a> and agree to the <a href="/TermsAndConditions" target="_blank">terms and
                            conditions</a>
                        </h3> */}

                        <div class="sign_up_width">


                            <div class="text-center">
                                <button type="submit">Next</button>
                            </div>

                            {/* <h5><span>or</span></h5>

                            <ul>
                                <li><a href="#"><img src="images/google-icon.png" alt="" /></a></li>
                                <li><a href="#"><img src="images/micro-icon.png" alt="" /></a></li>
                                <li><a href="#"><img src="images/facebook-icon.png" alt="" /></a></li>
                            </ul> */}

                            <h6>Already have an account? <Link to="/Login">Login</Link></h6>

                        </div>
                    </div>

                </div>



            </form>

        </div>


    )

}