import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BasicInfoSave, GetBasicInfo } from '../api';

export const SaveBasic = createAsyncThunk('SaveBasic', async (formData, { rejectWithValue }) => {
  try {
    const response = await BasicInfoSave(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const GetBasic = createAsyncThunk('GetBasicInfo', async (formData, { rejectWithValue }) => {
  try {
    const response = await GetBasicInfo(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const basicSlice = createSlice({
  name: 'basic',
  initialState: {
    data: null,
    success: false,
    error: null,
    basic: null, 
 
  },
  reducers: {

    clearState: state => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(SaveBasic.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(SaveBasic.fulfilled, (state, action) => {
        state.success = action.payload;
        state.error = null;
      })
      .addCase(SaveBasic.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
      .addCase(GetBasic.pending, state => {
        state.success = false;
        state.error = null;
      })
      .addCase(GetBasic.fulfilled, (state, action) => {
        state.basic = action.payload.data.data;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(GetBasic.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      })
  },
});
export const { clearState } = basicSlice.actions;

export default basicSlice.reducer;

