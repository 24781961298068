import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Sidebar from "../UserDashboard/Sidebar";
import { GetUploadedGovtIds, SaveGovtIds, clearGvt } from "../../../Reducer/StepForms/govtIdslice";
import { API_BASE_URL } from "../Data/content";
import { UserDetail } from "../../../Reducer/UserDashboardSlices/userSlice";

export default function GovtVerify() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [element, setElemeent] = useState("");
    const auth = useSelector(state => state.auth.auth);
    const [sidebarDisabled, setSidebarDisabled] = useState(true);
    const success = useSelector(state => state.govtId.success);
    const error = useSelector(state => state.govtId.error);
    const [view1, setView1] = useState(null);
    const [view2, setView2] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [err, setFormError] = useState("");
    const [msg, setMsg] = useState("");
    const [btnst, setBtn] = useState(false);
    const data = useSelector(state => state.user.data);

    if (success && success.status === 200) {
        if (success.data.data) {

            dispatch(clearGvt());
        } else {
            // Swal.fire({
            //     title: 'Congratulation!',
            //     text: success.data.message,
            //     icon: 'success'
            // });
            setMsg(success.data.message);
            setBtn(true);
            dispatch(clearGvt());
            // setTimeout(() => {
            //     navigate("/PrivacySetting");
            // }, 2000);
        }
    }
    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearGvt());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearGvt());
        }
    }

    const Next = () => {
        if (data.selected_plan == null) {
            navigate("/PrivacySetting");
        }
        else {
            if (data && data.basic === false) {
                navigate("/UserStepForms", { state: "Basic" });
            }
            else if (data && data.astro === false) {
                navigate("/UserStepForms", { state: "Astro" });
            }
            else if (data && data.families === false) {
                navigate("/UserStepForms", { state: "Family" });
            }
            else if (data && data.carrers === false) {
                navigate("/UserStepForms", { state: "Career" });
            }
            else if (data && data.spirituals === false) {
                navigate("/UserStepForms", { state: "Spiritual" });
            }
            else if (data && data.preferences === false) {
                navigate("/UserStepForms", { state: "Preferences" });
            }
            else if (data && data.personalityquestions === false) {
                navigate("/PersonalityQuestions");
            }
            else if (data && data.about === false) {
                navigate("/AboutPersonality");
            }
            else if (data && data.gallery === false) {
                navigate("/UploadGallery");
            }
            else if (data && data.selfy === false) {
                navigate("/SelfiVerification");
            }
            else if (data && data.privacy === false) {
                navigate("/PrivacySetting");
            } else {
                navigate("/Dashboard");
            }
        }
    }


    useEffect(() => {
        let datasss = { uid: auth._id };
        dispatch(UserDetail(datasss));
        getData();

    }, []);

    async function getData() {
        let dat = { uid: auth._id };
        let res = await dispatch(GetUploadedGovtIds(dat));
        if (res.payload && res.payload.data.data) {
            const responseData = res.payload.data.data;
            console.log('response data', responseData);
            if (responseData.back_img && responseData.front_img) {
                setView1(API_BASE_URL + responseData.front_img);
                setView2(API_BASE_URL + responseData.back_img);
                setFile1(responseData.front_img);
                setFile2(responseData.back_img);
            }
        }

    }

    const selectFile = (e) => {
        setMsg("");
        setFormError("");

        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
        const file = e.target.files[0];

        if (file.size <= maxSizeInBytes) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (fe) => {
                const image = new Image();
                image.src = fe.currentTarget.result;

                image.onload = function () {
                    // if (this.naturalHeight === 1024 && this.naturalWidth === 1024) {
                    setView1(URL.createObjectURL(file));
                    setFile1(file);
                    // } else {
                    //     setFormError("Please upload a valid image size (1024x1024).");
                    // }
                };

                image.onerror = function () {
                    setFormError("Error loading image. Please try again.");
                };
            };

            fileReader.onerror = () => {
                setFormError("Error reading file. Please try again.");
            };
        } else {
            setFormError("Please upload a file smaller than 2MB.");
        }
    };


    const selectFile2 = (e) => {
        setFormError("");
        const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes
        const file = e.target.files[0];

        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(e.target.files[0]);
        // fileReader.onload = (fe) => {
        //     const image = new Image();
        //     image.src = fe.currentTarget.result;
        //     image.onload = function (ie) {
        //         if (this.naturalHeight === 1024 && this.naturalWidth === 1024) {
        //             setView2(URL.createObjectURL(e.target.files[0]));
        //             setFile2(e.target.files[0]);
        //         } else {
        //             setFormError("Please upload valid image size");
        //         }

        //     }
        // }


        if (file.size <= maxSizeInBytes) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (fe) => {
                const image = new Image();
                image.src = fe.currentTarget.result;

                image.onload = function () {
                    // if (this.naturalHeight === 1024 && this.naturalWidth === 1024) {
                    setView2(URL.createObjectURL(file));
                    setFile2(file);
                    // } else {
                    //     setFormError("Please upload a valid image size (1024x1024).");
                    // }
                };

                image.onerror = function () {
                    setFormError("Error loading image. Please try again.");
                };
            };

            fileReader.onerror = () => {
                setFormError("Error reading file. Please try again.");
            };
        } else {
            setFormError("Please upload a file smaller than 2MB.");
        }

    }


    function childRes() {
        // props.sendres("privacy");
    }

    const upload = (e) => {
        if (view1 != null && view2 != null) {
            setFormError("");
            let formData = new FormData();
            formData.append('uid', auth._id);
            formData.append('front_img', file1);
            formData.append('back_img', file2);

            dispatch(SaveGovtIds(formData));

        }
        else {
            setFormError("Please upload both side images");
        }

    }



    return (
        <>
            <section id="full_page_dashboard_withoutSidebar">
                <div class="container-fluid">
                    {/* <Sidebar data={element} sendToParent={childRes} disabled={sidebarDisabled} /> */}
                    <div class="upload_photos">
                        <div class="row">
                            <div class="col-lg-6 pe-xl-5">
                                <div class="box">
                                    <h2><img src="images/Photos-upload-3.png" alt="" />
                                        <span>Verification</span>
                                        Govt. ID Verification
                                    </h2>
                                    <h3>ID Verification Guidelines</h3>

                                    <h4>Capture Clearly</h4>
                                    <p>Ensure a clear, unobstructed image of your entire, valid government-issued ID.</p>

                                    <h4>Well-Lit Environment</h4>
                                    <p>Take photos in a well-lit area, minimizing shadows and reflections on the ID surface.</p>

                                    <h4>Privacy and Security</h4>
                                    <p>Submit the original, unaltered ID image, knowing it is handled securely for verification
                                        purposes.</p>
                                    <p>Your thoughtful cooperation is truly appreciated! 📸🔒</p>

                                </div>
                            </div>

                            <div class="col-lg-6">

                                <div class="box-two">
                                    <div class="imagefile verify_gov two" style={{ backgroundImage: `url(${"images/img-upload-3.png"})` }} >
                                    </div>

                                    <h6><input type="file" onChange={selectFile} accept="image/*" /></h6>
                                    <h6><input className="change_placeholder" type="file" onChange={selectFile2} accept="image/*" /></h6>
                                    <p>Document size must be equal to or below 2 MB.</p>
                                </div>

                                <div className="row">
                                    <div className="col-6 mt-3">
                                        {view1 !== null && <img src={view1} alt="" class="w-100 h-100" />}
                                    </div>
                                    <div className="col-6 mt-3">
                                        {view2 !== null && <img src={view2} alt="" class="w-100 h-100" />}
                                    </div>
                                </div>


                                {err && <p className={"error__feedback"}>{err}</p>}
                            </div>

                            <div class="col-12 mt-5">
                                <Link to="/UserDashboard" class="btn ">Go to Dashboard</Link>
                                <Link to="/PrivacySetting" class="btn skip">Skip</Link>
                                <h5 class="heading_btn">

                                    {btnst === false ? <a class="btn upload" onClick={upload}>Upload</a> :
                                        <a class="btn upload" onClick={() => Next()}>Next</a>
                                        // <Link to="/PrivacySetting" class="btn upload">Next</Link>
                                    }


                                </h5>
                            </div>
                            {msg !== "" && <p class="SucessColor">{msg}</p>}



                        </div>
                    </div>
                </div>
            </section>


        </>
    )

}