import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sidebar from "../Sidebar";
import Header from "../Header";
import AllAccepted from "./AllAccepted";
import MyAccepted from "./MyAccepted";
import RecommendedAccepted from "./RecommendedAccepted";


export default function AcceptedMatches() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const auth = useSelector(state => state.auth.auth);
    const Tabbs = [
        {
            name: "All Accepted Matches",
            Component: <AllAccepted />
        },
        {
            name: "My Accepted Matches",
            Component: <MyAccepted />
        },
        {
            name: "Recommended Accepted Matches",
            Component: <RecommendedAccepted />
        }
    ];


    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {

    }, []);



    return (
        <>
            <section id="full_page_dashboard">
                <div class="container-fluid">
                    <Sidebar />

                    <section id="match_dashboard">
                        <Header />

                        <Tabs>
                            <TabList className={"nav nav-pills mb-3"} id="pills-tab" role="tablist">
                                {Tabbs.length > 0 && Tabbs.map((item, i) => (
                                    <Tab key={i}
                                        className={"nav-link" + (activeTab === i ? " active" : "")}
                                        onClick={() => handleTabClick(i)}
                                    >
                                        {item.name}
                                    </Tab>
                                ))}

                            </TabList>
                            {Tabbs.map((tem, index) => (
                                <TabPanel key={index} className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}>
                                    {tem.Component}
                                </TabPanel>
                            ))}
                        </Tabs>


                    </section>


                </div>
            </section>

        </>
    )

}